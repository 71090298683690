<template>
  <div>
      <el-pagination
        layout="prev, pager, next"
        :current-page="query.pageNumber"
        :page-size="query.pageSize"
        :page-sizes="pageSizes"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>

export default {
  name: "mypagination",
  props: {
    // 分页配置
    query: {
      type: Object,
      default() {
        return {};
      },
    },
    // 总数
    total: {
      type: Number,
      default: 0,
    },
    // 分页的页数集合
    pageSizes: {
      type: Array,
      default() {
        return [20, 50, 80, 100];
      },
    },
  },
  methods: {
    // 切换页数
    currentChange(val) {
      this.$emit("currentchange", val);
    },
    // 切换条数
    sizeChange(val) {
      this.$emit("sizechange", val);
    },
  },
};
</script>


<style scoped>
/deep/ .el-pager {
  font-size: 20PX;
}
/deep/ .number {
  font-size: 20PX;
}

/deep/ .active {
  color: #FE992B !important;
}

/deep/ .pagination-container.hidden {
  display: none;
}
</style>