<template>
<!--  公寓-->
  <div class="studentApartment">
    <div :class="screenWidth >= 992 ? 'hidden-xs-only main-service ' : 'hidden-xs-only main-service-sm '">
      <div class="search">
        <div>
          <input
              class="input"
              type="text"
              v-model="query.name"
              :placeholder="$t('studentapartment.请输入要搜索的房源')"
          />
        </div>
        <div>
          <div class="btn" @click="searchapartment">{{$t('studentapartment.搜索')}}</div>
        </div>
      </div>
      <div class="screen">
        <div @mousemove="mousemove(AddressList.length,1)" @mouseout="mouseout">
          <div class="caidan" >
            {{query.county||$t('studentapartment.区域')}}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
          <div class="xiala"  :style="{height:mouseheight}" >
            <div v-for="(item,index) in AddressList" :key='index' @click='contentchange(item.content,1)'>{{item.content}}</div>
          </div>
          
        </div>
        <div @mousemove="mousemove(schoolList.length,2)" @mouseout="mouseout">
          <div class="caidan">
            {{query.university.slice(0,6)||$t('studentapartment.学校')}}
            
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
          <div class="xiala"  :style="{height:mouseheight2}">
            <div v-for="(item,index) in schoolList" :key='index'  @click='contentchange(item.content,2)'>{{item.content}}</div>
          </div>
        </div>
        <div @mousemove="mousemove(periceList.length,3)" @mouseout="mouseout">
          <div class="caidan">
            {{rent||$t('studentapartment.租金')}}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
          <div class="xiala"  :style="{height:mouseheight3}">
            <div v-for="(item,index) in periceList" :key='index'  @click='contentchange(item,3)'>{{item}}</div>
          </div>
        </div>
        <div @mousemove="mousemove(renttype.length,4)" @mouseout="mouseout">
          <div class="caidan">
           {{query.leaseTermType||$t('studentapartment.租期类型') }}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
          <div class="xiala"  :style="{height:mouseheight4}">
            <div v-for="(item,index) in  renttype" :key='index'  @click='contentchange(item.content,4)'>{{item.content}}</div>
          </div>
        </div>
        <div  @mousemove="mousemove(hometype.length,5)" @mouseout="mouseout">
          <div class="caidan">
            {{query.roomType||$t('studentapartment.房间类型')}}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
           <div class="xiala"  :style="{height:mouseheight5}">
            <div v-for="(item,index) in hometype" :key='index'  @click='contentchange(item.content,5)'>{{item.content}}</div>
           </div>
        </div>
        <div  @mousemove="mousemove(sexlist.length,6)" @mouseout="mouseout">
          <div class="caidan">
          {{ query.genderRequire||$t('studentapartment.性别要求') }}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
           <div class="xiala"  :style="{height:mouseheight6}">
            <div v-for="(item,index) in sexlist" :key='index'  @click='contentchange(item.content,6)'>{{item.content}}</div>
          </div>
        </div>
      </div>
      <div class="title">{{$t('studentapartment.公寓列表')}}</div>
      <div class="list">
        <div class="card" v-for="(item, index) in apartmentList" :key="index" @click="jump(item)">
          <div class="img">
            <img :src="item.coverImage" alt=""/>
            <div class="huo">
              <img class="img1" src="../../assets/image/fire.png" alt=""/>
              {{item.viewsCount||0}}
            </div>
          </div>
          <div class="text">
            <template v-if="item.apartmentName.length>10">{{item.apartmentName.slice(0,9)}}...</template>
            <template v-else>{{item.apartmentName}}</template>
          </div>
          <div class="text2">
            <div>{{item.county}}</div>
            <div>{{item.leaseTermType}}</div>
          </div>
          <div class="text3">
            <div>{{item.county}}</div>
            <div>|</div>
            <div class="dizhi">
              <template v-if="item.university.length>9">{{item.university.slice(0,7)}}...</template>
              <template v-else>{{item.university}}</template>
            </div>
          </div>
          <div class="text4">HK {{item.rentMoney}}/{{$t('studentapartment.月起')}}</div>
        </div>
      </div>
       <div class="Hkpagination">
            <MyPagination
                :query='query'
                :total="total"
                @currentchange='handleCurrentChange'
                @sizechange='handleSizeChange'>
            </MyPagination>
          </div>
    </div>
    <!-- 手机端 -->
    <div class="hidden-sm-and-up apartment-phone main-service-sm">
         <div class="search">
            <div>
              <input
                  class="input"
                  type="text"
                  v-model="query.name"
                  :placeholder="$t('studentapartment.请输入要搜索的房源')"
              />
            </div>
            <div>
              <div class="btn"  @click="searchapartment">{{$t('studentapartment.搜索')}}</div>
            </div>
          </div>
          
      <div class="screen">
        <div  @mousemove="mousemove(AddressList.length,1)" @mouseout="mouseout">
          <div class="caidan">
            {{query.county||$t('studentapartment.区域')}}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
           <div class="xiala"  :style="{height:mouseheight}" >
            <div v-for="(item,index) in AddressList" :key='index' @click='contentchange(item.content,1)'>{{item.content}}</div>
          </div>
        </div>
        <div @mousemove="mousemove(schoolList.length,2)" @mouseout="mouseout">
          <div class="caidan"  >
            {{query.university.slice(0,6)||$t('studentapartment.学校')}}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
          <div class="xiala"  :style="{height:mouseheight2}">
            <div v-for="(item,index) in schoolList" :key='index'  @click='contentchange(item.content,2)'>{{item.content}}</div>
          </div>
        </div>
        <div  @mousemove="mousemove(periceList.length,3)" @mouseout="mouseout">
          <div class="caidan">
           {{ rent||$t('studentapartment.租金')}}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
          <div class="xiala"  :style="{height:mouseheight3}">
            <div v-for="(item,index) in periceList" :key='index'  @click='contentchange(item,3)'>{{item}}</div>
          </div>
        </div>
      </div>
      <div  class="screen">
        <div  @mousemove="mousemove(renttype.length,4)" @mouseout="mouseout">
          <div class="caidan">
            {{query.leaseTermType||$t('studentapartment.租期类型')}}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
          <div class="xiala"  :style="{height:mouseheight4}">
            <div v-for="(item,index) in  renttype" :key='index'  @click='contentchange(item.content,4)'>{{item.content}}</div>
          </div>
        </div>
        <div  @mousemove="mousemove(hometype.length,5)" @mouseout="mouseout">
          <div class="caidan">
            {{query.roomType||$t('studentapartment.房间类型')}}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
          <div class="xiala"  :style="{height:mouseheight5}">
            <div v-for="(item,index) in hometype" :key='index'  @click='contentchange(item.content,5)'>{{item.content}}</div>
           </div>
        </div>
        <div  @mousemove="mousemove(sexlist.length,6)" @mouseout="mouseout">
          <div class="caidan">
            {{query.genderRequire||$t('studentapartment.性别要求')}}
            <img src="../../assets/image/jiantou.png" alt=""/>
          </div>
           <div class="xiala"  :style="{height:mouseheight6}">
            <div v-for="(item,index) in sexlist" :key='index'  @click='contentchange(item.content,6)'>{{item.content}}</div>
          </div>
        </div>
      </div>
      <div class="title">{{$t('studentapartment.公寓列表')}}</div>
      <div class="list-sm">
        <div class="card" v-for="(item, index) in apartmentList" :key="index" @click="jump(item)">
          <div class="img">
            <img :src="item.coverImage" alt=""/>
            <div class="huo">
              <img class="img1" src="../../assets/image/fire.png" alt=""/>
              {{item.viewsCount||0}}
            </div>
          </div>
          <div class="text">
            <template v-if="item.apartmentName.length>10">{{item.apartmentName.slice(0,9)}}...</template>
            <template v-else>{{item.apartmentName}}</template>
          </div>
          <div class="text2">
            <div>{{item.county}}</div>
            <div>{{item.leaseTermType}}</div>
          </div>
          <div class="text3">
            <div>{{item.county}}</div>
            <div>|</div>
            <div class="dizhi">
              <template v-if="item.university.length>9">{{item.university.slice(0,7)}}...</template>
              <template v-else>{{item.university}}</template>
            </div>
          </div>
          <div class="text4">HK {{item.rentMoney}}/{{$t('studentapartment.月起')}}</div>
        </div>
      </div>
      
          <div class="Hkpagination">
            <MyPagination
                :query='query'
                :total="total"
                @currentchange='handleCurrentChange'
                @sizechange='handleSizeChange'>
            </MyPagination>
          </div>
    </div>
    <Mydialog ref="icon" @sumbit="submitchangetwo"></Mydialog>
    <Myicon @iconchange='iconchange'></Myicon>
  </div>
</template>

<script>
import MyPagination from '@/components/mypagination'
import "element-ui/lib/theme-chalk/display.css";
import Mydialog from "@/components/dialog"
import Myicon from '@/components/mainicon'
import {addMessageRecord,getSchool,getSearchSys,lifeRoomList} from '@/api/index.js'
export default {
  components: {
    MyPagination,
    Mydialog,
    Myicon
  },
  data() {
    return {
      search: "",
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      AddressList:[],
      schoolList:[],
      hometype:[],
      renttype:[],
      sexlist:[],
      mouseheight:0,
      mouseheight2:0,
      mouseheight3:0,
      mouseheight4:0,
      mouseheight5:0,
      mouseheight6:0,
      query:{
       pageNumber:1,// * @param pageNumber 必选 Integer 页数
       pageSize:30, //* @param pageSize 必选 Integer 每页显示的数量
       name:'',// * @param name 非必选 String 搜索房源值
        county:'',// @param county 非必选 String 区域
        university:'',// @param university 非必选 String 学校
        rentMoneyStart:'',//* @param rentMoneyStart 非必选 String 租金开始
        rentMoneyEnd:'',//* @param rentMoneyEnd 非必选 String 租金结束
        leaseTermType:'',//* @param leaseTermType 非必选 String 租期类型
        roomType:'',// @param roomType 非必选 String 房间类型
        genderRequire:'',//* @param genderRequire 非必选 String 性别要求
      },
      apartmentList:[],
      total:0,
      area:'',
      scholl:'',
      rent:'',
      renttypetwo:"",
      roomtype:'',
      sextype:''
    };
  },
  computed:{
    periceList(){
      return [
        this.$t('studentapartment.不限'),
        this.$t('studentapartment.三千'),
        this.$t('studentapartment.五千'),
        this.$t('studentapartment.七千'),
        this.$t('studentapartment.九千'),
        this.$t('studentapartment.以上'),
      ]
    }
  },
  mounted() {
    console.log(this.you)
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
        if (document.body.clientWidth <= 768) {
                 location.reload();
        }
      })();
    };
    this.searchapartment()
    setTimeout(()=>{
      this. _getSearchSys(3)
      this. _getSearchSys(4)
      this. _getSearchSys(6)
      this. _getSearchSys(7)
      this. _getSearchSys(9)
    },500)

  },
  methods: {
    //分页
    handleSizeChange(val) {
      this.query.pageSize=val
        this.searchapartment()
        console.log(`每页 ${val} 条`);

      },
      handleCurrentChange(val) {
        this.query.pageNumber=val+1
        this.searchapartment()
        console.log(`当前页: ${val}`);
      },
    searchapartment(){
      lifeRoomList(this.query).then(res=>{
        this.apartmentList=res.data.records
        console.log(res)
        this.total=res.data.total
      })
    },
    contentchange(content,e){
      if(e==1){//区域
        this.query.county=content
      }
       if(e==2){//学校
        this.query.university=content
      }
       if(e==3){//租金
       this.rent=content
       if(content=='不限'){
        this.query.rentMoneyStart=0
        this.query.rentMoneyEnd=100000
      this.searchapartment()
        return
       }
       if(content=='9000以上'){
        this.query.rentMoneyStart=9000
        this.query.rentMoneyEnd=100000
      this.searchapartment()
        return
       }
        this.query.rentMoneyStart=content.split('-')[0]
        this.query.rentMoneyEnd=content.split('-')[1]
        
      }
       if(e==4){//租期类型
        this.query.leaseTermType=content
      }
       if(e==5){//房间类型
        this.query.roomType=content
      }
       if(e==6){//性别要求
        this.query.genderRequire=content
      }
      this.searchapartment()
    },
    //获取筛选信息
    _getSearchSys(category){
      getSearchSys({
        category:category//3:房型内容4:租期类型内容6:性别要求7:附近大学9:区域
      }).then(res=>{
        console.log(res)
        if(category==3){
          this.hometype=res.data
        }
        if(category==4){
          this.renttype=res.data
        }
        if(category==6){
          this.sexlist=res.data
        }
        if(category==7){
          this.schoolList=res.data
        }
        if(category==9){
          this.AddressList=res.data
        }
        console.log(res.data)
      })
    },
    //获取学校
    _getSchool(){
      getSchool({
        addressId:''
      }).then(res=>{
        this.schoolList=res.data
      })
    },
    //在线咨询（学生公寓）
    submitchangetwo(e){
        e.category=5
        console.log(e.category)
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
    iconchange(){
      this.$refs.icon.dialogVisible=true
    },
    jump(e) {
      this.$router.push({
        name: "ApartmentDetails",
        query:{
          roomId:e.roomId,
          apartment:e
        }
      });
    },
    mousemove(e,f){
      console.log(e)
      if(f==1){
      this.mouseheight=e*43+'PX'
      }if(f==2){
      this.mouseheight2=e*43+'PX'
      }if(f==3){
      this.mouseheight3=e*43+'PX'
      }if(f==4){
      this.mouseheight4=e*43+'PX'
      }if(f==5){
      this.mouseheight5=e*43+'PX'
      }if(f==6){
      this.mouseheight6=e*43+'PX'
      }
    },
    mouseout(){
       console.log('222')
       this.mouseheight=0 
       this.mouseheight2=0
       this.mouseheight3=0
       this.mouseheight4=0
       this.mouseheight5=0
       this.mouseheight6=0
    }

  },
};
</script>

<style scoped lang='scss'>

.Hkpagination {
  margin: 0 auto;
  text-align: center;
}
.list {
  /* width: 1176px; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
}

.list > .card {
  width: 216px;
  margin-bottom: 24px;
  margin-right: 18px;
}

.list > .card > .text4 {
  text-align: left;
  // width: 134px;
  height: 28px;
  /* line-height: 28; */
  color: #f30404;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 600;
}

.list > .card > .text {
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 18px;
  color: #333;
  font-weight: 600;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 这里是超出几行省略 */
  overflow: hidden;
}

.list > .card > .text2 {
  display: flex;
  font-size: 12px;
  color: #999;
  margin-top: 6px;
  
}

.list > .card > .text2 > div {
  width: 52px;
  height: 24px;
  line-height: 24px;
  margin-right: 8px;
  white-space:nowrap;


}

.list > .card > .text3 {
  display: flex;
  font-size: 14px;
  color: #333;
  margin-top: 8px;
}

.list > .card > .text3 > div {
  /* width: 52px;
  height: 24px; */
  line-height: 24px;
  margin-right: 10px;
}

.list > .card > .text3 > .dizhi {
  margin-left: 10px;
}

.list > .card > .img {
  border-radius: 12px;
  position: relative;
}

.list > .card > .img > .huo {
  width: 98px;
  height: 28px;
  line-height: 28px;
  background-color: rgba(153, 153, 153, 0.8);
  border-radius: 0px 12px 0px 12px;
  position: absolute;
  bottom: 4px;
  color: #fff;
  font-size: 14px;
  padding-top: 2px;

}

.list > .card > .img > .huo > img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-bottom: 5px;
}

.list > .card > .img > img {
  width: 216px;
  height: 216px;
  border-radius: 12px;
}

.title {
  color: #333;
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 24px;
}

.studentApartment {
  width: 100%;
  background-color: #f7f7f7;
}

.main-service {
  width: 1176px;
  margin: 0 auto;
}

.main-service-sm {
  width: 1176px;
  margin: 0 auto;
}

.screen {
  width: 1176px;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  display: flex;
  /* align-items: center; */
  font-size: 14px;
}

.screen > div {
  position: relative;
  flex: 1;
}

.screen > div > .caidan > img {
  margin-bottom: 1px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.screen > div > .xiala > div {
  width: 100%;
  // height: 43px;
  line-height: 43PX;
  color: #fff;
  font-size: 14PX;
}

.screen > div > .xiala:hover {
  cursor: pointer;
}

.screen > div > .xiala > div:hover {
  background-color: rgb(238, 28, 28);

}

.screen > div > .xiala {
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: 4px;
  background-color: #fe992b;
  position: absolute;
  z-index: 999;
  transition: all 0.3s;
  /* display: none; */
}

.screen > div:hover .xiala {
  // height: 129px;
}

.search {
  /* margin-top: 30px; */
  padding-top: 30px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn {
  width: 117px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  background-color: #fe992b;
}

.input {
  width: 1031px;
  height: 60px;
  border-radius: 4px;
  background-color: #fff;
  border: none;
  font-size: 20px;
  outline: none;
  padding: 16px;
}

// 手机端
.apartment-phone{
  width:335PX;
  .search {
  /* margin-top: 30px; */
  padding-top: 30PX;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20PX;
}

.btn {
  width: 54PX;
  height: 34PX;
  line-height: 34PX;
  text-align: center;
  border-radius: 4PX;
  color: #fff;
  font-size: 12PX;
  background-color: #fe992b;
}

.input {
  width: 274PX;
  height: 34PX;
  border-radius: 4PX;
  background-color: #fff;
  border: none;
  font-size: 12PX;
  outline: none;
  padding: 16PX;
}

.screen {
  width: 335PX;
  height: 30PX;
  line-height: 30PX;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  font-size: 12PX;
  margin-top: 10PX;
  border-radius: 4PX;
}

.screen > div {
  // width: 30%;
  position: relative;
  flex: 1;
}

.screen > div > .caidan > img {
  margin-bottom: 4PX;
  width: 16PX;
  height: 16PX;
  vertical-align: middle;
}

.screen > div > .xiala > div {
  width: 100%;
  // height: 43PX;
  line-height: 43PX;
  color: #fff;
  font-size: 16PX;
}

.screen > div > .xiala:hover {
  cursor: pointer;
}

.screen > div > .xiala > div:hover {
  background-color: rgb(238, 28, 28);
}

.screen > div > .xiala {
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: 4PX;
  background-color: #fe992b;
  position: absolute;
  z-index: 999;
  transition: all 0.3s;
  line-height: 50PX;
  /* display: none; */
}

.screen > div:hover .xiala {
  // height: 129PX;
}

.title {
  color: #333;
  font-size: 16PX;
  font-weight: 600;
  text-align: left;
  margin-top: 20PX;
  margin-bottom: 16PX;
}
.list-sm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
 
}

.list-sm > .card {
  width: 105PX;
  margin-bottom: 24PX; margin-right: 6PX;
}

.list-sm > .card > .text4 {
  text-align: left;
  width: 100PX;
  height: 20PX;
  /* line-height: 28; */
  color: #f30404;
  font-size: 12PX;
  // margin-top: 10PX;
  font-weight: 600;
}

.list-sm > .card > .text {
  margin-top: 6PX;
  margin-bottom: 6PX;
  font-size: 10PX;
  color: #333;
  font-weight: 600;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 这里是超出几行省略 */
  overflow: hidden;
}

.list-sm > .card > .text2 {
  display: flex;
  text-align: left;
  font-size: 7PX;
  color: #999;
  margin-top: 6PX;
}

.list-sm > .card > .text2 > div {
  width: 30PX;
 margin-right: 10PX;
 text-align: center;
  height: 12PX;
  line-height: 12PX;
  background: #fff;
  // margin-left: 8PX;
}

.list-sm> .card > .text3 {
  display: flex;
  font-size: 8PX;
  color: #333;
  // margin-top: 8PX;
}

.list-sm > .card > .text3 > div {
  /* width: 52px;
  height: 24px; */
  line-height: 20PX;
  margin-right: 4PX;
}

.list-sm > .card > .text3 > .dizhi {
  // margin-left: 10PX;
}

.list-sm > .card > .img {
  border-radius: 12PX;
  position: relative;
}

.list-sm > .card > .img > .huo {
  width: 90PX;
  height: 20PX;
  line-height: 20PX;
  background-color: rgba(153, 153, 153, 0.8);
  border-radius: 0px 8PX 0px 8PX;
  position: absolute;
  bottom: 3PX;
  color: #fff;
  font-size: 12PX;
  padding-top: 0PX;
}

.list-sm > .card > .img > .huo > img {
  width: 12PX;
  height: 12PX;
  vertical-align: middle;
  margin-bottom: 5PX;
}

.list-sm > .card > .img > img {
  width: 105PX;
  height: 105PX;
  border-radius: 8px;
}
}
</style>
